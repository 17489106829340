<template>
  <div class="ModalFlex">
    <template v-if="create">
      <div class="Popup_Inner_main">
        <div @click="close()" class="Popup_close_btn">
          <img
            @load="imgLoaded"
            class="imgHide"
            src="@/assets/media/vectors/close.svg"
            alt=""
          />
        </div>
        <div class="Popup_title">Adaugă Plată</div>
        <div>
          <div class="form-group" :class="warn ? 'WRNG' : ''">
            <b>Valoarea plății</b>
            <inp-number v-model="inputs.val" />
          </div>
          <div class="form-group">
            <b>Comentariu</b>
            <textarea
              v-model.trim="inputs.comments"
              class="vInput"
              width="100%"
              cols=""
              rows="7"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="Popup_footer">
        <div class="Popup_footer_col">
          <button @click="close" class="Popup_footer_btn Footer_btn1">
            Inapoi
          </button>
        </div>
        <div class="Popup_footer_col">
          <button @click="save" class="Popup_footer_btn Footer_btn2">
            Adauga
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="Popup_Inner_main">
        <div @click="close()" class="Popup_close_btn">
          <img
            @load="imgLoaded"
            class="imgHide"
            src="@/assets/media/vectors/close.svg"
            alt=""
          />
        </div>
        <div class="Popup_title">Vezi Plăți</div>
        <div v-if="payments" class="TBParent">
          <table>
            <thead>
              <th>Valoare</th>
              <th style="text-align: center">Utilizator</th>
              <th style="text-align: center">Data creării</th>
              <th style="text-align: right">Acțiuni</th>
            </thead>
            <tbody>
              <template v-for="(e, i) in payments">
                <tr :key="e._Id">
                  <td>{{ e.paymentValue }}</td>
                  <td style="text-align: center">{{ readUserObject(e.createdByUser) }}</td>
                  <td style="text-align: center">{{ e.createdAt }}</td>
                  <td style="text-align: right">
                    <a
                      href="#"
                      @click.prevent="
                        showDetailsIndex = showDetailsIndex === i ? -1 : i
                      "
                      style="
                        display: inline-flex;
                        align-items: center;
                        color: #39f;
                      "
                      ><i
                        :class="`fas fa-${
                          showDetailsIndex === i ? 'minus' : 'plus'
                        }-circle`"
                        style="line-height: 0; margin-right: 0.25rem"
                      ></i>
                      {{ showDetailsIndex === i ? "Ascunde" : "Comentarii" }}</a
                    >
                    |
                    <a
                      href="#"
                      @click.prevent="deletePayment(e)"
                      style="
                        display: inline-flex;
                        align-items: center;
                        color: red;
                      "
                      ><i
                        class="fas fa-times-circle"
                        style="line-height: 0; margin-right: 0.25rem"
                      ></i>
                      Șterge</a
                    >
                  </td>
                </tr>
                <tr :key="`COMMENT_${e._Id}`" v-if="showDetailsIndex === i" class="TBGray">
                  <td colspan="4">
                    <pre v-if="e.comments" style="max-height: 20rem">{{
                      e.comments
                    }}</pre>
                    <h4
                      v-else
                      class="prettyH4"
                      style="margin-bottom: 1rem; margin-top: 0"
                    >
                      <i class="fas fa-times"></i>
                      Nu sunt comentarii
                    </h4>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <h4 v-else class="prettyH4" style="color: #888">
          <i class="fas fa-times"></i>
          Nu sunt plăți
        </h4>
      </div>
      <div class="Popup_footer">
        <div class="Popup_footer_col">
          <button @click="close" class="Popup_footer_btn Footer_btn1">
            Inapoi
          </button>
        </div>
        <div class="Popup_footer_col"></div>
      </div>
    </template>
  </div>
</template>

<script>
import { EXPENSES_PAYMENTS } from "@/api.js";
export default {
  props: {
    expenseData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    expenseId() {
      return this.expenseData.id ?? null;
    },
    create() {
      return !!this.expenseData.create;
    },
    totalPayments() {
      if (!Array.isArray(this.expenseData.data?.projectExpensePayments))
        return 0;

      return this.expenseData.data.projectExpensePayments.length
        ? this.expenseData.data.projectExpensePayments.reduce((a, b) =>
            this._Sum(a.paymentValue, b.paymentValue)
          )
        : 0;
    },
    payments() {
      return Array.isArray(this.expenseData.data.projectExpensePayments) &&
        this.expenseData.data.projectExpensePayments.length
        ? this.expenseData.data.projectExpensePayments
            .map((e) => ({
              ...e,
              createdAt: this.toDateAndTime(e.createdAt),
              paymentValue: this.toPriceFormat(e.paymentValue),
              _Id: e.id ?? this.makeid(6),
            }))
            .reverse()
        : null;
    },
    previousInputs() {
      return { ...this.inputs };
    },
  },
  data() {
    return {
      inputs: {
        val: "",
        comments: "",
      },
      warn: false,
      showDetailsIndex: -1,
    };
  },
  watch: {
    previousInputs(curr, old) {
      if (curr.val !== old.val) this.warn = false;
    },
  },
  methods: {
    close(x) {
      this.$emit("Close", !!x);
    },
    save() {
      if (!this.inputs.val || !this.inputs.val > 0) {
        this.$toastr.w("Introduceți valoarea plății.", "Atentie!");
        this.warn = true;
        return;
      }
      const x = parseFloat(this.expenseData.data?.valoareaContractata);
      const y = this._Minus(x, this.totalPayments);
      const z = this._Minus(y, this.inputs.val);

      if (typeof x == "number" && z < 0) {
        if(y => 0) {
          this.inputs.val = y
        }
        this.$toastr.w(
          y < 0
            ? `Suma totală a plăților deja depășește valoarea contractată a cheltuielei cu <b>${y}</b>.` :
            y === 0 ? `Suma totală a plăților deja este egală cu valoarea contractată a cheltuielei.` : 
            `Ați depășit suma permisă cu <b>${this.toPriceFormat(Math.abs(z))}</b>.`,
          "Atentie!"
        );
        this.warn = true;
        return;
      }

      this.send(this.inputs.val);
    },
    send(paymentValue) {
      if (!Number.isInteger(this.expenseId)) return;

      this.setSafeLoad();
      const err = (msg) => {
        this.$toastr.e(msg || "Crearea plății a eșuat.");
        this.setLoad();
        this.close(true);
      };

      EXPENSES_PAYMENTS.add(
        Object.assign(
          {
            projectExpenses: this.expenseId,
            paymentValue,
          },
          this.inputs.comments ? { comments: this.inputs.comments } : {}
        )
      )
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s("Plata a fost creată cu succes.");

            this.close(true);
            this.setLoad();
          } else {
            err();
          }
        })
        .catch(err);
    },
    deletePayment(e) {
      if (!Number.isInteger(e?.id)) {
        this.$toastr.e("Error #5531");
        return;
      }

      this.setSafeLoad();
      const err = (msg) => {
        this.$toastr.e(msg || "Ștergerea plății a eșuat.");
        this.close(true);
        this.setLoad();
      };

      EXPENSES_PAYMENTS.delete(e.id)
        .then((res) => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s("Plata a fost ștearsă cu succes.");

            this.close(true);
            this.setLoad();
          } else {
            err();
          }
        })
        .catch(err);
    },
  },
};
</script>

<style>
</style>