<template>
  <div v-tooltip="tooltip">
    <v-select
      v-model="curr"
      :label="label"
      :options="viewData"
      :searchable="searchable"
      :clearable="clearable"
      :filterable="filterable"
      :loading="loading || loadingModules"
      :multiple="multiple"
      :disabled="disabled"
      emptyLabel="Scrieți pentru a căuta"
      @search="search"
      :getOptionKey="x => x._Id"
    >
      <template #option="el">
        <span class="v-select_li_custom">
          <span>{{el[label]}}</span>
          <i v-tooltip="el[labelDesc || label]" class="fas fa-info-circle"></i>
        </span>
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'PaginatedDropdown',
  props: {
    value: {
      default: null
    },
    label: {
      type: String,
      default: 'label'
    },
    tooltip: {
      type: String,
      default: ''
    },
    labelDesc: {
      type: String,
      default: ''
    },
    recordsPerPage: {
      type: Number,
      default: 50
    },
    searchModule: {
      type: String,
      default: ''
    },
    searchKey: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    searchable: {
      type: Boolean,
      default: true
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    prepareQuery: {
      type: Function,
      default: (x,y,z,v) => [x, y, `${z}=${v}`]
    },
    timeout: {
      type: Number,
      default: 400
    },
    enableCache: {
      type: Boolean,
      default: true
    },
    reload: {
      type: [Number, String, Object]
    },
    loadStart: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      loadingModules: false,
      curr: this.value,
      modules: null,
      currentPage: 1,
      searchVal: '',
      timeoutApi: window['apiTimeout'](this.timeout),
      searchCache: [],
      deleteCache: false
    }
  },
  watch: {
    curr(x) {
      this.$emit('input', x)
    },
    recordsPerPage() {
      this.restartOffset()
    },
    reload() {
      this.restartOffset()
      this.curr = null
      if(this.loadStart && !this.disabled) {
        this.getNext(true)
      }
    }
  },
  computed: {
    module() {
      if(!this.searchModule || !this.modules) return null

      const findModule = this.modules[this.searchModule]
      return findModule ? (typeof findModule.get == 'function' ? findModule.get : findModule) : null
    },
    canNext() {
      return this.module && this.searchVal
    },
    viewData() {
      return this.options.slice(0, 100)
    }
  },
  methods: {
    async loadModules() {
      this.loadingModules = true
      this.modules = await import('@/api.js')
      this.loadingModules = false
    },
    search(search, loading) {
      if(this.deleteCache) {
        this.restartOffset()

        this.deleteCache = false
      }
      this.timeoutApi(async () => {
        this.searchVal = search
        if(!this.searchVal || (this.enableCache && this.searchCache.includes(search))) return

        loading(true)
        await this.getNext()
        loading(false)
      })
    },
    restartOffset() {
      this.searchCache = []
      this.options.splice(0)
    },
    async getNext(force) {
      if(!this.canNext && !force) return

      return await new Promise(resolve => {
        (x => {
          const prepareQuery = this.prepareQuery(this.currentPage, this.recordsPerPage, this.searchKey || '', x)
          if(!Array.isArray(prepareQuery)) return this.$toastr.e('Căutarea a eșuat. #213')
          const err = msg => {
            this.$toastr.e(msg || 'Căutarea a eșuat.')
            resolve(true)
          }

          this.module(...prepareQuery).then(res => {
            if(Array.isArray(res?.data?.result)) {
              res = res.data.result.filter(e => !this.options.find(x => x.id === e.id))
              
              this.searchCache.push(x)
              
              if(res.length) {
                
                this.options.push(...res)
                this.options.forEach((e,i) => e._Id = i)
              }
              resolve(true)
            } else {
              err()
            }
          }).catch(err)
        })(this.searchVal)
      })
    }
  },
  created() {
    if(this.searchModule && this.searchKey) {
      this.loadModules()

      this.$watch('options', {
        handler(x) {
          if(this.deleteCache) return

          if(x?.length > 200) {
            this.deleteCache = true
          }
        },
        deep: true
      })
    }
  }
}
</script>

<style>

</style>