<template>
  <div style="padding-left: 1.75rem">
    <clasic-modal v-model="inputsOpen" :disabledBg="true" extendedMode="1">
      <Input
        :Data="inputsData[0]"
        :Options="inputsData[1]"
        :All="inputsData[2]"
        :projectId="projectId"
        @Close="(x) => setModal(false, x)"
      />
    </clasic-modal>
    <clasic-modal
      v-model="paymentsOpen"
      :extendedMode="paymentsData.create ? '' : '1'"
    >
      <Payments
        :expenseData="paymentsData"
        @Close="
          (x) => {
            paymentsOpen = false;
            if (x) reloadPage();
          }
        "
      />
    </clasic-modal>
    <div class="col2_head">
      <div>
        <router-link :to="'/'" class="breadcrumb_home">Acasă / </router-link>
        <router-link to="/proiecte" class="breadcrumb_home">
          <span> Proiecte / </span>
        </router-link>
        <span> {{ $route.name }}</span>
      </div>
      <div class="subHeadTitle">{{ $route.name }}</div>
      <div class="right" style="margin-top: 1.2rem">
        <div v-if="PERMISIONS.create" class="right">
          <div
            @click="setModal(true)"
            style="position: absolute; right: 0; bottom: 0.5rem"
            class="Button1"
          >
            <div class="icon">
              <img
                @load="imgLoaded"
                src="@/assets/media/for_table/circle_ADD_new_etap.svg"
                alt=""
              />
            </div>
            <span class="Btn_content"> Adaugă cheltuială </span>
          </div>
        </div>
      </div>
    </div>
    <Table
      v-if="PERMISIONS.list"
      :key="tableReload"
      :Data="data"
      :fixedFilter="fixedFilter"
      @Btn2="editProject"
      @Btn3="deleteProject"
      @Btn7="showFiles"
      @Btn18="viewPayments"
      @Btn19="(x) => viewPayments(x, true)"
      @readFilteredData="filteredDataManager"
      @fullReload="reloadPage"
    />
    <template v-else>
      <h3 style="display: flex; flex-direction: column; align-items: center">
        <span style="font-size: 4rem">
          <i class="fas fa-exclamation-triangle"></i>
        </span>
        Acces interzis
      </h3>
    </template>
  </div>
</template>

<script>
import PERMISIONS from "@/mixins/projectsPermMixin.js";
import { mapMutations } from "vuex";
import DialogModal from "@/components/DialogModal";
import Table from "@/components/Table";
import Input from "@/components/ProjectsExpensesInput";
import Payments from "@/components/ProjectsExpensesPayments";
import { EXPENSES } from "@/api";

const inputsData = () => [
  {
    contractFel: null,
    contractCategory: null,
    contractType: null,
    estimatedContractDate: null,
    contractor: "",
    contractDate: null,
    finalTerm: null,
    categoriaCheltuieli: null,
    valoareaContractata: "",
    valoareaSolicitata: "",
    valoareaPenalitate: "",
    details: "",
    contractNr: "",
  },
  {
    contractFel: [
      { key: "de baza", label: "De bază" },
      { key: "act aditional", label: "Act adițional" },
    ],
    contractCategory: [
      { key: "produse", label: "Produse", type: 1 },
      { key: "servicii", label: "Servicii", type: 2 },
      { key: "lucrari", label: "Lucrări", type: 3 },
    ],
    achizitionStage: [
      { key: "finalizat", label: "Finalizat" },
      { key: "evaluare", label: "Evaluare" },
      { key: "in desfasurare", label: "În desfășurare" },
    ],
  },
  {},
];

export default {
  mixins: [PERMISIONS],
  name: "ProjectExpenses",
  $Title() {
    return `ConectX - ${this.$route.name}`;
  },
  data() {
    return {
      inputsData: inputsData(),
      tableReload: 0,
      paymentsData: {},
      inputsOpen: false,
      paymentsOpen: false,
      dataLoading: false,
      data: {
        options: {
          searchModule: "EXPENSES",
        },
        head: [
          {
            title: "NR.",
            minimize: 2,
            sort: true,
            fixed: {
              left: "0px",
            },
          },
          {
            title: "Data creării",
            sort: true,
            queryKey: "createdAt",
            bottomHead: {
              quikFiltre: "date",
              range: true,
            },
          },
          {
            title: "Fel contract",
            sort: true,
            queryKey: "contractFel",
            bottomHead: {
              quikFiltre: "list",
              options: [
                { value: "de baza", label: "De bază" },
                { value: "act aditional", label: "Act adițional" },
              ],
            },
          },
          {
            title: "Categorie contract",
            sort: true,
            queryKey: "contractCategory",
            bottomHead: {
              quikFiltre: "list",
              options: [
                { value: "produse", label: "Produse", type: 1 },
                { value: "servicii", label: "Servicii", type: 2 },
                { value: "lucrari", label: "Lucrări", type: 3 },
              ],
            },
          },
          {
            title: "Tip contract",
            sort: true,
            queryKey: "contractType.contractType",
            metaType: "CONTRACT_TYPE",
            bottomHead: {
              quikFiltre: true,
              multiselect: true,
            },
          },
          {
            title: "Stadiul achizitie",
            sort: true,
            queryKey: "achizitionStage",
            bottomHead: {
              quikFiltre: "list",
              options: [
                { value: "finalizat", label: "Finalizat" },
                { value: "evaluare", label: "Evaluare" },
                { value: "in desfasurare", label: "În desfășurare" },
              ],
            },
          },
          {
            title: "Data estimata contractare",
            sort: true,
            queryKey: "estimatedContractDate",
            bottomHead: {
              quikFiltre: "date",
              range: true,
            },
          },
          {
            title: "Contractor",
            sort: true,
            queryKey: "contractor",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Nr. contract",
            sort: true,
            queryKey: "contractNr",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Data contract",
            sort: true,
            queryKey: "contractDate",
            bottomHead: {
              quikFiltre: "date",
              range: true,
            },
          },
          {
            title: "Termen final",
            sort: true,
            queryKey: "finalTerm",
            bottomHead: {
              quikFiltre: "date",
              range: true,
            },
          },
          {
            title: "Categorie cheltuieli",
            sort: true,
            queryKey: "categoriaCheltuieli.cpvCode",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Valoare contractata",
            sort: true,
            queryKey: "valoareaContractata",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Valoare platita",
            sort: true,
            queryKey: "valoareaPlatita",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Ramas de plata",
            sort: true,
            queryKey: "",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Stadiul financiar",
            sort: true,
            queryKey: "stadiulFinanciar",
            bottomHead: {
              quikFiltre: true,
            },
          },
          {
            title: "Progres fizic",
            sort: true,
            queryKey: "progresFizic",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Valoare solicitata",
            sort: true,
            queryKey: "valoareaSolicitata",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Valoare penalitate",
            sort: true,
            queryKey: "valoareaPenalitate",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            title: "Valoare rambursata",
            sort: true,
            queryKey: "valoareaRambursata",
            bottomHead: {
              quikFiltre: "numberFloat",
              range: true,
            },
          },
          {
            spacer: true,
            minimize: 1,
            fixed: {
              right: true,
            },
            bottomHead: {
              quikFiltre: "clear",
            },
          },
        ],
        body: [],
      },
      expenses: [],
      currentPage: this.getPaginatedParams(),
      tableFilter: {
        query: "",
      },
      totalItemsLength: 0,
    };
  },
  computed: {
    projectId() {
      return parseInt(this.$route.params.id) || null;
    },
    fixedFilter() {
      return `eq=projectId,${this.projectId}`;
    },
  },
  watch: {
    inputsOpen(x) {
      if (!x) {
        this.inputsData = inputsData();
      }
    },
    paymentsOpen(x) {
      if (!x) {
        this.paymentsData = {};
      }
    },
    expenses(x) {
      this.data.body =
        x?.length && x[0] !== false ? x.map((e) => e.row) : [false];
      this.tableReload++;
    },
  },
  methods: {
    ...mapMutations(["addWindow"]),
    filteredDataManager(data, callback) {
      if (Number.isInteger(data?.data?.recordsQuantity)) {
        this.totalItemsLength = data.data.recordsQuantity;
      }
      if (Array.isArray(data?.data?.result)) {
        callback([
          this.prepareData(data.data.result, {
            minimize: true,
          }).map((e) => e.row),
          data.data.recordsQuantity,
        ]);
        this.projectsData = data.data.result;
        this.data.options.totalItems = parseInt(data.data.recordsQuantity);
        this.currentPage = this.getPaginatedParams();
        this.dataLoading = false;
      } else {
        callback([false]);
      }
    },
    getItem(index) {
      return this.expenses.find((e) => e.index === index) || null;
    },
    showFiles(index) {
      const find = this.getItem(index);
      if (!Number.isInteger(find?.id)) return;

      this.addWindow({
        fileList: [find.data.attachedDocument],
        select: 0,
        itemId: find.id,
        searchModule: "EXPENSES",
        canDelete: false,
        modal: 13,
      });
    },
    viewPayments(index, create = false) {
      const find = this.getItem(index);
      if (!this.isObject(find?.data) || !Number.isInteger(find?.id)) return;

      this.paymentsData = {
        id: find.id,
        data: find.data,
        create,
      };
      this.paymentsOpen = true;
    },
    editProject(index) {
      const find = this.getItem(index);
      if (!this.isObject(find?.data) || !Number.isInteger(find?.id)) return;

      const x = Object.keys(this.inputsData[0]);
      const y = this.inputsData[1];
      const prepare = {};
      x.forEach((e) => {
        prepare[e] = y[e]
          ? y[e].find(({ key }) => key == find.data[e])
          : find.data[e] || "";
      });

      this.inputsData[2] = find.data;
      this.inputsData[0] = {
        ...prepare,
        __docs__: find.data.attachedDocument || null,
        __existent__: find.id,
      };
      this.inputsOpen = true;
    },
    deleteProject(index) {
      const find = this.getItem(index);
      if (!Number.isInteger(find?.id)) return;

      this.$modal.show(
        DialogModal,
        {
          target: "ProjectExpenseDelete",
          title: "Ștergere cheltuială",
          content:
            '<span style="color:#f44336;display: block;margin-top: 2rem;"><i class="fas fa-exclamation-circle" style="font-size: 6rem;"></i> <p style="font-weight: 500;font-size: 2.2rem;">Ești sigur că vrei să ștergi cheltuiala selectată?</p></span>',
          closeBtnText: "Anulează",
          button: {
            type: 3,
            value: "Sterge",
            handler: () => {
              const err = (msg) =>
                this.$toastr.e(
                  msg || "Ștergerea cheltuielii a eșuat."
                );

              this.setSafeLoad();
              EXPENSES.delete(find.id)
                .then((res) => {
                  if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
                    this.$toastr.s(
                      "Cheltuiala a fost ștearsă cu succes."
                    );
                  } else {
                    err();
                  }
                  this.reloadPage();
                  this.setLoad();
                })
                .catch((msg) => {
                  err(msg);
                  this.setLoad();
                  this.reloadPage();
                });
            },
          },
        },
        {
          name: "ProjectExpenseDelete",
          adaptive: true,
          width: "650",
          height: "400",
        }
      );
    },
    reloadPage() {
      this.data.body = [];
      this.tableReload = 0;
      this.totalItemsLength = 0;
      this.dataLoading = false;
      this.expenses = [];
      this.getExpenses();
    },
    setModal(x, reload) {
      this.inputsOpen = !!x;
      if (!x) {
        this.inputsData = inputsData();
      }
      if (reload) this.reloadPage();
    },
    getExpenses() {
      if (this.projectId === null || this.dataLoading) return;

      this.dataLoading = true;
      const err = (msg) => {
        this.$toastr.e(msg || "Proiectul nu a fost găsit");
        this.backToProjects();
        this.dataLoading = false;
      };

      EXPENSES.get(1, 100, this.fixedFilter)
        .then((res) => {
          this.dataLoading = false;
          if (Array.isArray(res?.data?.result)) {
            res = res.data.result;
            this.totalItemsLength = res.length;
            this.expenses = res.length ? this.prepareData(res) : [false];
          } else {
            err();
          }
        })
        .catch(err);
      // PROJECTS.getOne(this.projectId)
      // .then((res) => {
      //   this.dataLoading = false;
      //   if (Array.isArray(res?.data?.result) && res.data.result[0] && Array.isArray(res?.data?.result[0]?.expenses)) {
      //     this.projectData = { ...res.data.result[0] };
      //     res = res.data.result[0].expenses;
      //     this.totalItemsLength = res.length;
      //     this.expenses = res.length ? this.prepareData(res) : [false];
      //   } else {
      //     err();
      //   }
      // })
      // .catch(err);
    },
    prepareData(response) {
      const prepare = [];
      let i = 0;
      for (const r of response) {
        const y = { ...this.inputsData[1] };
        Object.entries(y).forEach((e) => {
          const x = e[1].find(({ key }) => key == r[e[0]]);
          y[e[0]] = x ? x.label : "-";
        });

        const preparation = [
          i + 1,
          this.toDate(r.createdAt) || "-",
          y["contractFel"] || "-",
          y["contractCategory"] || "-",
          r.contractType?.contractType || "-",
          y["achizitionStage"] || "0",
          this.toDate(r.estimatedContractDate),
          r.contractor || "-",
          r.contractNr || "-",
          this.toDate(r.contractDate),
          this.toDate(r.finalTerm),
          r.categoriaCheltuieli?.cpvCode || "-",
          r.valoareaContractata
            ? this.toPriceFormat(r.valoareaContractata)
            : "0,00",
          r.valoareaPlatita ? this.toPriceFormat(r.valoareaPlatita) : "0,00",
          r.ramasDePlata
            ? this.toPriceFormat(Math.abs(r.ramasDePlata))
            : "0,00",
          r.stadiulFinanciar
            ? `${this.calcPercent(r.stadiulFinanciar)}%`
            : "0,00",
          r.progresFizic || "0",
          r.valoareaSolicitata
            ? this.toPriceFormat(r.valoareaSolicitata)
            : "0,00",
          r.valoareaPenalitate
            ? this.toPriceFormat(r.valoareaPenalitate)
            : "0,00",
          r.valoareaRambursata
            ? this.toPriceFormat(r.valoareaRambursata)
            : "0,00",
        ];

        preparation.push([
          [
            [
              18,
              "Vezi plăți",
              ["fas fa-list", "font-size:2.2rem;color:#2196f3;"],
            ],
            [
              19,
              "Adaugă plată",
              ["fas fa-cart-plus", "font-size:2.2rem;color:#2196f3;"],
            ],
            [7, "Vezi fișiere"],
            2,
            3,
          ],
        ]);

        const returnData = {
          row: preparation,
          data: r,
          id: r.id,
          index: i,
        };
        prepare.push(returnData);
        i++;
      }
      prepare.forEach((e, i) => {
        e.NR = i;
        e.index = i;
      });
      return prepare;
    },
    backToProjects() {
      this.$router.push(`/${this.$route.params.navigation}/proiecte`);
    },
  },
  components: {
    Table,
    Input,
    Payments,
  },
  beforeDestroy() {
    this.inputsOpen = false;
    this.$forceUpdate();
  },
  created() {
    if (!this.PERMISIONS.list) {
      return;
    }

    this.getExpenses();
  },
  mounted() {
    this.setLoad()
  },
};
</script>