<template>
  <div class="ModalFlex">
    <div class="Popup_Inner_main">
      <div @click="close" class="Popup_close_btn">
        <img
          @load="imgLoaded"
          class="imgHide"
          src="@/assets/media/vectors/close.svg"
          alt=""
        />
      </div>
      <div class="Popup_title">
        {{ existent ? 'Modifică' : 'Adaugă' }} Cheltuială
      </div>
      <div class="vInputDate">
        <div class="form-header">
          Informații contract
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[0]">
            <b>Fel contract</b>
            <v-select
              v-model="D.contractFel"
              :searchable="false"
              :options="Options.contractFel"
            />
          </div>
          <div class="form-group" :class="warns[1]">
            <b>Categorie contract</b>
            <v-select
              v-model="D.contractCategory"
              :searchable="false"
              :options="Options.contractCategory"
            />
          </div>
          <div class="form-group" :class="warns[8]">
            <b>Categorie cheltuieli</b>
            <paginated-dropdown
              v-model="D.categoriaCheltuieli"
              label="cpvCode"
              searchModule="CPV_CATEGORY_PAGINATED"
              searchKey="cpvCode"
              labelDesc="name"
              :tooltip="
                currCpvType ? '' : 'Mai întâi selectați categoria contractului'
              "
              :disabled="!currCpvType"
              :reload="currCpvType"
              :prepareQuery="
                currCpvType
                  ? (x, y, z, v) => [x, y, currCpvType, [z, v]]
                  : () => {}
              "
            />
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[2]">
            <b>Tip contract</b>
            <v-select
              v-model="D.contractType"
              :options="contract.data"
              :loading="!contract.data.length && !contract.empty"
              label="contractType"
            />
          </div>
          <div class="form-group" :class="warns[6]">
            <b>Data contract</b>
            <date-picker
              v-model="D.contractDate"
              valueType="YYYY-MM-DDTHH:mm:ssZ"
            />
          </div>
          <div class="form-group" :class="warns[4]">
            <b>Data estimată contractare</b>
            <date-picker
              v-model="D.estimatedContractDate"
              valueType="YYYY-MM-DDTHH:mm:ssZ"
            />
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[5]">
            <b>Contractor</b>
            <input v-model="D.contractor" type="text" class="vInput" />
          </div>
          <div class="form-group" style="margin-bottom: 0;" :class="warns[17]">
            <b>Nr. contract</b>
            <input v-model="D.contractNr" type="text" class="vInput" />
          </div>
          <files-manager
            v-model="contractFile.file"
            label="Document însoțitor"
            :classes="warns[18]"
            :max="1"
          />
        </div>

        <div class="form-header">
          Informații generale
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group">
            <b>Stadiul achiziție</b>
            <v-select
              :value="Options.achizitionStage[2]"
              :searchable="false"
              :options="Options.achizitionStage"
              :disabled="true"
              :clearable="false"
              :no-drop="true"
            />
          </div>
          <div class="form-group" :class="warns[7]">
            <b>Termen final</b>
            <date-picker
              v-model="D.finalTerm"
              valueType="YYYY-MM-DDTHH:mm:ssZ"
              :disabled-date="dateFeatureChecker"
            />
          </div>
        </div>
        <div class="form-group" :class="warns[16]">
          <b>Detalii</b>
          <textarea
            v-model="D.details"
            class="vInput"
            width="100%"
            cols=""
            rows="7"
          ></textarea>
        </div>

        <div class="form-header">
          Informații financiare
        </div>
        <div v-if="existent" class="form-flex form-adapt-1">
          <div class="form-group">
            <b>Stadiul fizic</b>
            <input
              :value="toFixedIfNecessary(All.stadiulFizic, 4)"
              type="text"
              class="vInput form-disabled"
              disabled="disabled"
            />
          </div>
          <div class="form-group">
            <b>Stadiul financiar</b>
            <input
              :value="toFixedIfNecessary(All.stadiulFinanciar, 4)"
              type="text"
              class="vInput form-disabled"
              disabled="disabled"
            />
          </div>
          <div class="form-group">
            <b>Progres fizic</b>
            <input
              :value="toFixedIfNecessary(All.progresFizic, 4)"
              type="text"
              class="vInput form-disabled"
              disabled="disabled"
            />
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[9]">
            <b>Valoare contractată</b>
            <inp-number v-model="D.valoareaContractata" />
          </div>
          <div class="form-group" :class="warns[13]">
            <b>Valoare solicitată</b>
            <inp-number v-model="D.valoareaSolicitata" />
          </div>
        </div>
        <div class="form-flex form-adapt-1">
          <div class="form-group" :class="warns[14]">
            <b>Valoare penalitate</b>
            <inp-number v-model="D.valoareaPenalitate" />
          </div>
          <div class="form-group">
            <b>Valoare rambursată</b>
            <input
              :value="valoareaRambursata"
              type="text"
              class="vInput form-disabled"
              disabled="disabled"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="Popup_footer">
      <div class="Popup_footer_col">
        <button @click="close" class="Popup_footer_btn Footer_btn1">
          Inapoi
        </button>
      </div>
      <div class="Popup_footer_col">
        <button @click="save" class="Popup_footer_btn Footer_btn2">
          Salveaza
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { EXPENSES, CONTRACT } from '@/api.js'
import DatePicker from '@/mixins/DatePicker.js'
import FilesAgent from '@/components/FilesAgent'
import PaginatedDropdown from '@/components/PaginatedDropdown'

const prepareObjMap = [
  'categoriaCheltuieli',
  'contractFel',
  'contractCategory',
  'contractType',
]
const prepareNumberMap = [
  'valoareaContractata',
  'valoareaSolicitata',
  'valoareaPenalitate',
]

export default {
  mixins: [DatePicker],
  components: {
    'files-manager': FilesAgent,
    'paginated-dropdown': PaginatedDropdown,
  },
  props: {
    Data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    Options: {
      type: Object,
      required: true,
      default: () => ({}),
    },
    projectId: {
      type: Number,
      default: 0,
    },
    All: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    const data = {
      D: this.Data,
      inputsWarnings: {},
      existent: Number.isInteger(this.Data.__existent__),
      modifyId: this.Data.__existent__ ?? null,
      attachedDocuments: this.Data.__docs__ ?? null,
      existentData: {},
      contract: {
        empty: false,
        data: [],
        value: null,
      },
      contractFile: {
        file: [],
        wrng: false,
      },
    }

    delete this.Data.__existent__
    delete this.Data.__docs__

    return data
  },
  computed: {
    totalPayments() {
      if (!Array.isArray(this.All?.projectExpensePayments)) return 0

      return this.All.projectExpensePayments.length
        ? this.All.projectExpensePayments.reduce((a, b) =>
            this._Sum(a.paymentValue, b.paymentValue)
          )
        : 0
    },
    valoareaRambursata() {
      return this.toPriceFormat(
        this.D.valoareaSolicitata !== '' && this.D.valoareaPenalitate !== ''
          ? this._Minus(this.D.valoareaSolicitata, this.D.valoareaPenalitate)
          : 0
      )
    },
    lastData() {
      return Object.assign({}, this.D)
    },
    currCpvType() {
      return this.D.contractCategory?.type || null
    },
    warns() {
      const x = this.inputsWarnings
      const y = key => (x[key] ? 'WRNG' : '')
      return {
        0: y('contractFel'),
        1: y('contractCategory'),
        2: y('contractType'),
        4: y('estimatedContractDate'),
        5: y('contractor'),
        6: y('contractDate'),
        7: y('finalTerm'),
        8: y('categoriaCheltuieli'),
        9: y('valoareaContractata'),
        13: y('valoareaSolicitata'),
        14: y('valoareaPenalitate'),
        16: y('details'),
        17: y('contractNr'),
        18: this.contractFile.wrng ? 'WRNG' : '',
      }
    },
  },
  watch: {
    lastData: {
      handler(val, old) {
        Object.entries(val).forEach(e => {
          if (e[1] !== old[e[0]]) {
            this.$set(this.inputsWarnings, e[0], false)
          }
        })
      },
      deep: true,
    },
  },
  methods: {
    close(x) {
      this.$emit('Close', x === true)
    },
    prepareValidatorDate(x) {
      if (!x) return ''

      return new Date(x)
    },
    save() {
      let succes = true
      if (!this.contract.data.length) {
        this.$toastr.w('Tipurile de contract nu au fost încărcate!', 'Atentie')
        succes = false
      }
      if (this.existent && this.D.valoareaContractata < this.totalPayments) {
        this.$toastr.w(
          `Valoarea contractată este mai mică de cât suma totală a plăților.<p>Valoarea totală este: <b>${this.toPriceFormat(
            this.totalPayments
          )}</b></p>`,
          'Atentie'
        )
        this.$set(this.inputsWarnings, 'valoareaContractata', true)
        succes = false
      }

      const prepared = {}

      Object.entries(this.D).forEach(e => {
        if (e[1] && prepareObjMap.includes(e[0])) {
          prepared[e[0]] = e[1].id ?? e[1].key ?? null
        }
        if (!e[1] || !String(e[1]).trim()) {
          this.$set(this.inputsWarnings, e[0], true)
          succes = false
        }
      })
      if (!this.contractFile.file.length) {
        if (succes)
          this.$toastr.w('Este nevoie să atașați un fișier!', 'Atentie!')
        this.contractFile.wrng = true
        succes = false
      }

      if (succes) {
        this[this.existent ? 'editProject' : 'createProject'](prepared)
      }
    },
    editProject(prepared) {
      const id = this.modifyId
      const x = { ...this.D, ...prepared }
      const y = this.existentData
      const prepare = {}
      const currFile = this.contractFile.file.length
        ? this.contractFile.file[0]
        : null

      let includeAutocals = false

      Object.entries(x).forEach(e => {
        if (e[1] != (y[e[0]]?.key || y[e[0]]?.id || y[e[0]])) {
          if (prepareNumberMap.includes(e[0])) includeAutocals = true
          prepare[e[0]] = e[1]
        }
      })

      if (this.isObjEmpty(prepare) && currFile.__OLD_FILE__) {
        this.$toastr.w('Nu au fost găsite modificări.', 'Atentie!')
      } else {
        if (includeAutocals) {
          prepare['valoareaRambursata'] = this.getLocaleNumber(
            this.valoareaRambursata
          )
        }

        const err = msg =>
          this.$toastr.e(msg || 'Modificarea cheltuielii a eșuat.')

        const prepareData = new FormData()
        Object.entries(prepare).forEach(e => prepareData.append(e[0], e[1]))
        if (!currFile.__OLD_FILE__) prepareData.append('file', currFile.file)

        this.setSafeLoad()
        EXPENSES.edit(id, prepareData)
          .then(res => {
            if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
              this.$toastr.s('Cheltuiala a fost modificată cu succes.')
            } else {
              err()
            }

            this.close(true)
            this.setLoad(false)
          })
          .catch(msg => {
            this.close(true)
            this.setLoad(false)
            err(msg)
          })
      }
    },
    createProject(prepared) {
      const err = msg => this.$toastr.e(msg || 'Crearea cheltuielii a eșuat.')
      const x = { ...this.D, ...prepared }
      const prepareData = new FormData()
      prepareData.append('projectId', this.projectId)
      prepareData.append('file', this.contractFile.file[0].file)
      Object.entries(x).forEach(e => prepareData.append(e[0], e[1]))

      this.setSafeLoad()
      EXPENSES.add(prepareData)
        .then(res => {
          if (this.checkHttpStatusCode(res?.meta?.HttpStatusCode)) {
            this.$toastr.s('Cheltuiala a fost creată cu succes.')
          } else {
            err()
          }

          this.close(true)
          this.setLoad(false)
        })
        .catch(msg => {
          this.close(true)
          this.setLoad(false)
          err(msg)
        })
    },
    getContract() {
      const err = msg => {
        this.$toastr.e(msg || 'Obținerea tipurilor de contract a eșuat.')
        this.contract.empty = true
      }

      CONTRACT.get(1, 500)
        .then(res => {
          if (
            this.checkHttpStatusCode(res?.meta?.HttpStatusCode) &&
            Array.isArray(res.data?.result)
          ) {
            if (res.data.result.length) {
              this.contract.data = res.data.result

              if (this.existent) {
                const contractId = this.D?.contract?.id
                const find = this.contract.data.find(e => e.id == contractId)
                if (find) {
                  this.contract.value = find
                }
              }
            } else {
              this.contract.empty = true
            }
          } else {
            err()
          }
        })
        .catch(msg => {
          err(msg)
        })
    },
  },
  created() {
    this.getContract()

    if (this.existent) {
      prepareNumberMap.forEach(e => {
        const x = parseFloat(this.Data[e])
        this.Data[e] = isNaN(x) ? '' : String(x)
      })
      this.existentData = { ...this.Data }
      if (this.attachedDocuments) {
        const e = this.attachedDocuments
        this.contractFile.file = [
          {
            file: {
              name: this.getNeedFileName(e),
            },
            key: e,
            deleted: false,
            __OLD_FILE__: true,
          },
        ]
      }
    }

    this.$watch('contractFile.file', {
      handler() {
        this.contractFile.wrng = false
      },
      deep: true,
    })
  },
}
</script>
